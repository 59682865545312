import { UserNavigation } from "@/common/types/layout.types";
import { signOut } from "next-auth/react";
export const AdminSettingsMenu: UserNavigation[] = [
  {
    name: "Your Profile",
    href: "/admin/settings",
  },
  {
    name: "Watto Ribs",
    href: "/admin/adminRibs",
  },
  {
    name: "Sign out",
    href: "#",
    onClick: () => signOut(),
  },
];

export const OrganizationSettingsMenu: UserNavigation[] = [
  {
    name: "Profil",
    href: "/organization/settings",
  },
  {
    name: "Se déconnecter",
    href: "#",
    onClick: () => signOut(),
  },
];
