import { Reload } from "styled-icons/ionicons-outline";

type Props = {
  resetError: () => void;
  error: any;
  componentStack: any;
};

const ErrorFallback = ({ error, componentStack, resetError }: Props) => {
  return (
    <div className="flex flex-col justify-center items-center px-6 mx-auto h-[80%] xl:px-0 dark:bg-gray-900">
      <div className="block md:max-w-lg">
        <img src="/images/500.svg" alt="astronaut image" />
      </div>
      <div className="text-center xl:max-w-4xl">
        <h1 className="mb-3 text-2xl font-bold leading-tight text-gray-900 sm:text-4xl lg:text-5xl dark:text-white">
          Quelque chose a gravement dérapé
        </h1>
        <p className="mb-5 text-base font-normal text-gray-500 md:text-lg dark:text-gray-400">
          {process.env.NODE_ENV !== "production"
            ? error.message
            : "Nous rencontrons actuellement des problèmes techniques. Veuillez réessayer plus tard."}
        </p>
        <a
          onClick={() => resetError()}
          className="text-white cursor-pointer bg-green-400 hover:bg-green-500 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-3 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
        >
          <Reload />
          <span className="mx-2"> Réessayez</span>
        </a>
      </div>
    </div>
  );
};

export default ErrorFallback;
