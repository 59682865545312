import Link from "next/link";

export default function Unauthorized() {
  return (
    <div className="flex h-screen items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-16">
          <div className="text-center">
            <p className="text-base font-semibold text-blue-600 uppercase tracking-wide">
              Non autorisé
            </p>
            <h2 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              Vous essayez d&apos;accéder à l&apos;inaccessible ?
            </h2>

            <div className="mt-6">
              <Link href="/" passHref>
                <a className="text-base font-medium text-blue-600 hover:text-blue-500">
                  Retour à la page d&apos;accueil
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
