import { useSession } from "next-auth/react";
import { useEffect, useState, ReactNode } from "react";
import { SideBarNavigation, UserNavigation } from "../types/layout.types";
import { AdminLinks } from "./data/admin.data";
import { OrganizationLinks } from "./data/organization.data";
import Footer from "./footer";
import Header from "./header";
import MobileMenu from "./mobile-menu";
import Sidebar from "./sidebar";
import { AdminSettingsMenu, OrganizationSettingsMenu } from "./data/menu.data";
import Head from "next/head";

type Props = {
  children: ReactNode;
};

const Layout = ({ children }: Props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const { data: session, status }: any = useSession();
  const [role, setRole] = useState(session.user.role);
  const [sideBarNav, setSideBarNav] = useState<SideBarNavigation[]>([]);
  const [settingsMenu, setSettingsMenu] = useState<UserNavigation[]>([]);

  useEffect(() => {
    if (role === "ADMIN") {
      setSettingsMenu(AdminSettingsMenu);
      setSideBarNav([...AdminLinks]);
    }
    if (role === "ORGANIZATION") {
      setSettingsMenu(OrganizationSettingsMenu);
      setSideBarNav(OrganizationLinks);
    }
  }, [role]);

  if (status !== "authenticated") return <div>{children}</div>;

  return (
    <>
      <Head>
        <title>
          {role === "Admin - Tableau de bord"
            ? "Admin"
            : `${session?.user?.name} - Tableau de bord`}
        </title>
      </Head>
      <div className="h-screen bg-gray-50 flex overflow-hidden">
        <Sidebar navigation={sideBarNav} />
        <MobileMenu
          navigation={sideBarNav}
          menuState={{ mobileMenuOpen, setMobileMenuOpen }}
        />

        <div className="flex-1 flex flex-col overflow-hidden">
          <Header
            userNavigation={settingsMenu}
            menuState={{ mobileMenuOpen, setMobileMenuOpen }}
          />

          <div className="flex-1 flex items-stretch overflow-hidden">
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
              {children}
              <Footer />
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

Layout.auth = true;
export default Layout;
