import { Menu, Transition } from "@headlessui/react";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import { signOut, useSession } from "next-auth/react";
import { Fragment, useEffect, useState } from "react";
import { HeaderProps } from "../types/layout-props";
import classNames from "../utils/class-names.utils";
import { PowerOff } from "@styled-icons/boxicons-regular/PowerOff";
import Image from "next/image";
import { useRouter } from "next/router";

const Header = ({ userNavigation, menuState }: HeaderProps) => {
  const router = useRouter();
  const logMeOut = () => {
    signOut();
  };

  const [avatar, setAvatar] = useState("/default.jpg");
  const { data: session }: any = useSession();

  useEffect(() => {
    if (session.user.image) setAvatar(session.user.image);
  }, [session]);

  return (
    <header className="w-full">
      <div className="relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
          onClick={() => menuState?.setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 flex justify-between px-4 sm:px-6">
          <div className="flex-1 flex">
            <form className="w-full flex md:ml-0" action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">
                Search all files
              </label>
              <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                {/* Search place */}
              </div>
            </form>
          </div>
          <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
            <Menu as="div" className="relative flex-shrink-0">
              <div>
                <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500  hover:outline-none hover:ring-2 hover:ring-offset-2 hover:ring-blue-500">
                  <span className="sr-only">Open user menu</span>
                  <div className="relative h-8 w-8 rounded-full overflow-hidden">
                    <Image
                      src={avatar}
                      alt="avatar"
                      layout="fill"
                      objectFit="contain"
                    />
                  </div>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <a
                          onClick={() => router.push(item.href)}
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 duration-200 cursor-pointer"
                          )}
                        >
                          {item.name}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>

            <button
              aria-label="logout"
              onClick={logMeOut}
              type="button"
              className="flex bg-blue-500 p-1 rounded-full items-center justify-center text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <PowerOff className="h-6 w-6 text-white" aria-hidden="true">
                <span className="sr-only">Logout</span>
              </PowerOff>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
