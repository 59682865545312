import { SideBarNavigation } from "@/common/types/layout.types";
import {
  CashIcon,
  HomeIcon,
  SupportIcon,
  UsersIcon,
  CurrencyDollarIcon,
  OfficeBuildingIcon,
  CalculatorIcon,
  IdentificationIcon,
} from "@heroicons/react/outline";

export const OrganizationLinks: SideBarNavigation[] = [
  { name: "Tableau de bord", href: "/organization", icon: HomeIcon },
  {
    name: "Mes documents",
    href: "/organization/documents",
    icon: IdentificationIcon,
  },
  {
    name: "Magasins",
    href: "/organization/store",
    icon: OfficeBuildingIcon,
  },
  {
    name: "Utilisateurs",
    href: "/organization/suborg",
    icon: UsersIcon,
  },
  {
    name: "Paiements",
    href: "/organization/payments",
    icon: CashIcon,
  },
  {
    name: "Soldes",
    href: "/organization/balance",
    icon: CurrencyDollarIcon,
  },

  {
    name: "Facturation",
    href: "/organization/accounting",
    icon: CalculatorIcon,
  },

  {
    name: "Support",
    href: "/organization/support",
    icon: SupportIcon,
  },
];
