import "../../styles/globals.css";
import { SessionProvider, useSession } from "next-auth/react";
import Spinner from "@/common/pages/spinner";
import Layout from "@/common/layout/layout";
import { Fragment, useEffect, useState } from "react";
import Unauthorized from "@/common/pages/unauthorized";
import { ToastContainer } from "react-toastify";
import * as yup from "yup";
import { fr } from "yup-locales";
import "react-toastify/dist/ReactToastify.css";
import Head from "next/head";
import { ErrorBoundary } from "@sentry/nextjs";
import ErrorFallback from "@/common/components/page/error-boundary";
import Loader from "@/common/components/loader/main-loader";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
} from "@tanstack/react-query";
import "react-loading-skeleton/dist/skeleton.css";

yup.setLocale(fr);

const queryClient = new QueryClient();

function useHasMounted() {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted;
}

function MyApp({
  Component,
  pageProps: { session, ...pageProps },
  ...appProps
}: any) {
  const hasMounted = useHasMounted();

  const path = appProps.router.pathname;
  const isLayoutNeeded =
    path.startsWith("/admin") ||
    path.startsWith("/organization") ||
    appProps.router.pathname === "/";

  const LayoutComponent = isLayoutNeeded ? Layout : Fragment;

  if (!hasMounted) return <Loader />;
  return (
    <Fragment>
      <Head>
        <title>Watto</title>
      </Head>

      <SessionProvider session={pageProps.session}>
        <QueryClientProvider client={queryClient}>
          <QueryErrorResetBoundary>
            {({ reset }) => (
              <ErrorBoundary fallback={ErrorFallback} onReset={reset}>
                {Component.auth ? (
                  <PreAuth>
                    <LayoutComponent>
                      <Auth>
                        <Component {...pageProps} />
                      </Auth>
                    </LayoutComponent>
                  </PreAuth>
                ) : (
                  <Component {...pageProps} />
                )}
                <ReactQueryDevtools initialIsOpen={false} />
                <ToastContainer />
              </ErrorBoundary>
            )}
          </QueryErrorResetBoundary>
        </QueryClientProvider>
      </SessionProvider>
    </Fragment>
  );
}

function Auth({ children }: any) {
  const { data: session, status } = useSession({ required: true });
  if (status === "loading") return <Spinner />;

  const user: any = session?.user;

  if (
    children.type.auth.role === user.role ||
    children.type.auth.role === "ANY"
  ) {
    return children;
  } else {
    return <Unauthorized />;
  }
}

function PreAuth({ children }: any) {
  const { data: session, status } = useSession({ required: true });
  if (status === "loading") return <Spinner />;
  const isUser = !!session?.user;

  if (isUser) {
    return children;
  } else {
    return <Unauthorized />;
  }
}

export default MyApp;
