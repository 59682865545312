import { AdjustmentsIcon } from "@heroicons/react/outline";
import Link from "next/link";
import { useRouter } from "next/router";
import { SideBarProps } from "../types/layout-props";
import classNames from "../utils/class-names.utils";
import Image from "next/image";

const Sidebar = ({ navigation }: SideBarProps) => {
  const router = useRouter();
  return (
    <div className="hidden w-28 bg-gray-900 overflow-y-auto md:block">
      <div className="w-full py-6 flex flex-col items-center">
        <div className="flex-shrink-0 flex items-center">
          <div className="h-10 w-12 relative">
            <Image
              layout="fill"
              src="/ayv.svg"
              alt="ayv studio"
              priority={true}
            />
          </div>
        </div>
        <div className="flex flex-col mt-6 min-h-[85vh] w-full">
          <div className="flex-1 w-full px-2 space-y-1">
            {navigation.map(
              (item) =>
                item.name !== "Settings" && (
                  <Link href={item.href} key={item.name} passHref>
                    <a
                      className={classNames(
                        router.pathname === item.href
                          ? "bg-blue-500 text-white"
                          : "text-blue-100 hover:bg-blue-400 hover:text-white",
                        "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      <item.icon
                        className={classNames(
                          router.pathname === item.href
                            ? "text-white"
                            : "text-blue-400 group-hover:text-white",
                          "h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      <span className="mt-2 text-white text-center">
                        {item.name}
                      </span>
                    </a>
                  </Link>
                )
            )}
          </div>
          {navigation.find((item) => item.name === "Settings") && (
            <div className="px-2">
              <Link href={"/organization/settings"} passHref>
                <a
                  key={"Settings"}
                  className={classNames(
                    router.pathname === "/organization/settings"
                      ? "bg-blue-500 text-white"
                      : "text-blue-100 hover:bg-blue-400 hover:text-white",
                    "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium cursor-default"
                  )}
                >
                  <AdjustmentsIcon
                    className={classNames(
                      router.pathname === "/organization/settings"
                        ? "text-white"
                        : "text-blue-400 group-hover:text-white",
                      "h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  <span className="mt-2">{"Settings"}</span>
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
